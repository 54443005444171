<template>
  <v-row v-scroll="onScroll">
    <!-- tab -->
    <v-col cols="12" class="px-0 pt-0 grey-background" :style="`position:fixed !important; top: 50px; z-index: 5; min-width: 860px; max-width: calc(100% - ${$store.state.base.isExpandNavigationDrawer ? 267 : 105}px); ${scroll > 100 ? 'box-shadow: 2px 4px 6px -6px rgba(69, 90, 100, 0.15);' : ''}`">
      <v-tabs class="pt-5" v-model="selectedItem" :key="`${$store.state.base.isExpandNavigationDrawer}-${reset}`" :optional="false">
        <v-tab
          v-for="(item, i) in list"
          :key="`${i}-${reset}`"
          :to="item?.children?.length ? null : { name: item.route, params: $route.params}"
          :ripple="false"
          >
          <template v-if="!item?.children?.length">
            <div class="ml-n1" style="min-width: 30px">
              <v-img contain :src="require(`@/assets/account/${item.svg}-icon${$route.name === item.route ? '-active' : $vuetify.theme.dark ? '-dark' : ''}.svg`)" :height="14" />
            </div>
            {{item.text === 'SII' ? $t('generals.SII') : item.text}}
          </template>
        </v-tab>
      </v-tabs>
    </v-col>
    <!-- end tab -->
    <v-col class="px-1" style="margin-top: 60px;">
      <router-view />
    </v-col>
  </v-row>
</template>
<script>
import MiniHeader from '@/components/commons/MiniHeader'
export default {
  components: {
    MiniHeader
  },
  data: () => ({
    scroll: 0,
    reset: 0,
    selectedItem: 0,
    current: '',
    list: [
      {
        text: 'Organización',
        route: 'AccountBasic',
        icon: 'domain',
        svg: 'account',
        disabled: false
      },
      {
        text: 'SII',
        route: 'IrsBuiltInSettings',
        icon: 'bank',
        svg: 'sii',
        disabled: false
      },
      // {
      //   text: 'Directorio',
      //   route: 'AccountMembers',
      //   icon: 'account-group',
      //   disabled: false,
      //   svg: 'members',
      //   dependentPaths: ['AccountGroupsCreate', 'AccountMembersRetrieve', 'AccountMemberUpdate', 'AccountGroups', 'AccountMembers', 'AccountGroupsRetrieve', 'AccountGroupsUpdate'],
      //   children: [
      //     {
      //       text: 'Usuarios',
      //       disabled: false,
      //       route: 'AccountMembers',
      //       parent: 'Directorio'
      //     },
      //     {
      //       text: 'Roles',
      //       disabled: false,
      //       route: 'AccountGroups',
      //       parent: 'Directorio'
      //     }
      //   ]
      // },
      {
        text: 'Developers',
        icon: 'xml',
        disabled: false,
        svg: 'developers',
        route: 'Developers'
      },
      {
        text: 'POS',
        icon: 'store',
        disabled: false,
        svg: 'pos',
        route: 'AccountSendLogPos'
      }
    ]
  }),
  computed: {
    currentList () {
      return this.list?.filter(({showSupport}) => this.$store.state.auth.userAccount.is_staff && showSupport) ?? []
    },
    currentParams () {
      let params = JSON.parse(JSON.stringify(this.$route.params))
      delete params.id
      return params
    }
  },
  created () {
    const index = this.list.findIndex((item) => item?.dependentPaths?.includes(this.$route.name))
    if (index !== -1) this.selectedItem = index

    this.reset += 1
  },
  methods: {
    onScroll (e) {
      this.scroll = e.target?.scrollingElement?.scrollTop ?? 0
    },
    goTo (route = '') {
      this.$router.push({ name: route}).catch(err => err)
    }
  }
}
</script>